body {
  margin: 0;
}

/* fix react-contexify menu beiing dimmed under semantic-ui dimmer */
/* semantic-ui dimmer has z-index of 1000, so add 1 */
.react-contexify {
  z-index: 1001;
}

.ui.selection.dropdown {
  background-color: #767676;
}

.ui.dropdown .menu {

  background-color: #292929;
}

.ui.dropdown .menu>.item {
  color: #cccccc;
}
.ui.dropdown .menu>.item:hover{
  color: #FFFFFF;
}



.ui.selection.dropdown{
  color: #FFFFFF;
}

.ui.search.dropdown>input.search {
  color: #CCCCCC;
}

.ui.dropdown .menu>.message:not(.ui) {
    color: #CCCCCC;
}

.ui.dropdown .menu .active.item {
  color: #CCCCCC;
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
  color: #FFFFFF;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.draggable {
  cursor: grab;
}
